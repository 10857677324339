var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header"),
        _c("div", { staticClass: "login-box", style: { height: _vm.height } }, [
          _c("img", {
            staticStyle: { width: "600px" },
            attrs: { src: "https://www.achencybe.com/img/pc/login/5-1.jpg" },
          }),
          _c(
            "div",
            { staticClass: "form-box" },
            [
              _c("img", {
                attrs: { src: "https://www.achencybe.com/img/pc/logo_1.png" },
              }),
              _c(
                "el-form",
                {
                  key: "form",
                  ref: "form",
                  staticStyle: { width: "280px" },
                  attrs: { rules: _vm.rules, model: _vm.form },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "email" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("user_info.edit_page_email"),
                        },
                        model: {
                          value: _vm.form.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("user_info.submit")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }